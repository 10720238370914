import { Button, Card, Box, useDisclosure, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, CardBody, CardFooter, CardHeader, Heading, Flex, Spinner, SimpleGrid, Text, VStack, HStack, Table, Thead, Tbody, Tr, Th, Td, ButtonGroup, Tfoot, TableContainer, Input } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useBotsContext } from "../hooks/useBotsContext"
import { useAuthContext } from "../hooks/useAuthContext"
import BotCard from '../components/BotCard'
import { useNavigate, Link } from 'react-router-dom';
import { Link as ChakraLink } from '@chakra-ui/react'
import {Helmet} from "react-helmet";
import { useLogout } from '../hooks/useLogout'
import UpgradeModal from '../components/UpgradeModal'
import CreateAnalysisButton from '../components/analysis/CreateAnalysisButton'
import LTDBanner from '../components/LTDBanner'

function VideoModalDiscoveryButton() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const videoUrl = "https://www.youtube.com/embed/JIWgynG_dJ4?si=Y9nzpT2_f7Hcm6Nc";

  return (
    <>
      <Button w = "100%" variant = "outline" colorScheme="green" mt="2" onClick={onOpen}>How it Works</Button>

      <Modal size = "2xl" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay/>
        <ModalContent>
          <ModalHeader>How Discovery Chatbots Work</ModalHeader>
          <ModalCloseButton />
          <ModalBody align = "center">
            <iframe 
              width="560" 
              height="315" 
              src={videoUrl} 
              title="YouTube video player" 
              frameBorder="0" 
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
              allowFullScreen>
            </iframe>
          </ModalBody>
          <ModalFooter>
            <Button
            variant="outline"
            colorScheme="green"
            mr={5}
            onClick={() => window.open("https://youtube.com/playlist?list=PLbqqvBuc7fb9R7s20Yy8dWfKygievzCCI&si=2KzJEAj7zaqM0Zba", "_blank")}
            isExternal
            >
            More Videos
            </Button>
            <Button colorScheme='green' onClick={onClose}>
              Get Started!
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );

  
}

function VideoModalAnalysisButton() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const videoUrl = "https://www.youtube.com/embed/QPAZTMVIv7w?si=n20HTLBKmcSL9IrT";

  return (
    <>
      <Button w = "100%" variant = "outline" colorScheme="green" mt="2" onClick={onOpen}>How it Works</Button>

      <Modal size = "2xl" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay/>
        <ModalContent>
          <ModalHeader>How Analysis Chatbots Work</ModalHeader>
          <ModalCloseButton />
          <ModalBody align = "center">
            <iframe 
              width="560" 
              height="315" 
              src={videoUrl} 
              title="YouTube video player" 
              frameBorder="0" 
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
              allowFullScreen>
            </iframe>
          </ModalBody>
          <ModalFooter>
            <Button
            variant="outline"
            colorScheme="green"
            mr={5}
            onClick={() => window.open("https://youtube.com/playlist?list=PLbqqvBuc7fb9R7s20Yy8dWfKygievzCCI&si=2KzJEAj7zaqM0Zba", "_blank")}
            isExternal
            >
            More Videos
            </Button>
            <Button colorScheme='green' onClick={onClose}>
              Get Started!
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );

  
}

function DataWaitlistButton(email) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const dataWaitlist = localStorage.getItem('dataWaitlist');

  useEffect(() => {
    if (dataWaitlist === 'true') {
      setSubmitted(true);
      setSuccessMessage('You have been added to the waitlist.');
    }
  }, [dataWaitlist]);

  const handleSubmit = async () => {

    const encodedData = new URLSearchParams();
    encodedData.append('EMAIL', email.email);
    

    const url = `https://botsheets.us21.list-manage.com/subscribe/post-json?u=ab884a53f68f1e3335e5da125&id=45a538160e&f_id=00d8e9e1f0&${encodedData.toString()}`;

    try {
        setLoading(true);
        const headers = {
          'Accept': '*/*',
          'Accept-Language': 'en-US,en;q=0.9',
          'Accept-Encoding': 'gzip, deflate',
          'Sec-Fetch-Dest': 'script',
          'Sec-Fetch-Mode': 'no-cors',
          'Sec-Fetch-Site': 'cross-site'
        };

        const response = await fetch(url, {
            method: 'GET',
            headers: headers,
            mode: 'no-cors'
        });

        localStorage.setItem('dataWaitlist', 'true');
        setSubmitted(true);
        setSuccessMessage('You have been added to the waitlist.');

    } catch (error) {
        setLoading(false);
        setSubmitted(false);
        setErrorMessage('An error occurred.');
    }
  };

  return (
    <>
      {successMessage && <Box mt = "3" color="green.500">{successMessage}</Box>}
      {errorMessage && <Box mt = "3"color="red.500">{errorMessage}</Box>}
      {!submitted && <Button isLoading={loading} w = "100%" mt = "3" colorScheme="green" onClick={handleSubmit}>Join Waitlist</Button>}
    </>
  );
}


const Chatbots = () => {

  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { logout } = useLogout()

  const { isOpen: isStatusModalOpen, onOpen: openStatusModal, onClose: closeStatusModal } = useDisclosure();
  const { isOpen: isUpgradeModalOpen, onOpen: openUpgradeModal, onClose: closeUpgradeModal } = useDisclosure()
  const params = new URLSearchParams(window.location.search);
  const attempt = params.get("attempt");
  const status = params.get("status");
  const message = params.get("message");

  const handleClick = () => {

    if (window.innerWidth <= 768) {
      if (confirm('You are on a mobile device and may not perform to your expectations. This app has been optimized for desktop and laptops. Continue anyways?')) {
        console.log('yes');
      } else {
        console.log('no');
        return;
      }
    }

    //make a get request to the api to check create
    fetch(`${process.env.REACT_APP_API_URL}/create/check`, {
      method: 'GET',
      headers: {'Authorization': `Bearer ${user.data.token}`},
    })
    .then(response => {
      if (!response.ok) {
        console.log('check response', response)
        throw new Error(response.statusText)
      }
      return response.json()
    })
    .then(data => {
      // instead of navigate, force the page to actually reload when navigating to create
      if (data.status === "Bot Limit") {
        throw new Error("Bot Limit")
      } else if (data.status === "Permissions") {
        throw new Error("Permissions")
      } else {
        window.location.href = '/create'
      }
    })
    .catch((error) => {
      if (error.message === "Bot Limit") {
        openUpgradeModal()
      } else {
        onOpen()
      }
    });
  };

  const {bots, dispatch} = useBotsContext();
  const {user} = useAuthContext();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchBots = async () => {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/bot`, {
        headers: {'Authorization': `Bearer ${user.data.token}`},
      })
      const json = await response.json()

      if (response.ok) {
        dispatch({type: 'SET_BOTS', payload: json})
        setLoading(false)
        console.log(json)
      }
    }

    if (user) {
      fetchBots()
      console.log(user.data._doc.tokens.scope)
      if (!user.data._doc.tokens.scope.includes('spreadsheets')) {
        onOpen()
      }
    }
  }, [dispatch])

  useEffect(() => {
    if (attempt === "true") {
      openStatusModal();
    }
  }, [attempt, openStatusModal]);

  console.log(user)
  

  if (loading) {
    return (
    <Flex mt = "10" justify = "center">
      <Spinner
        thickness='8px'
        speed='0.65s'
        emptyColor='gray.200'
        color='green.700'
        size='xl'
      />
    </Flex>
    )
  }

  const handleManage = (bot_id) => {
    window.location.href = '/manage/' + bot_id + '/settings'
  }

  const handleViewSheet = (bot_id) => {
    window.location.href = '/manage/' + bot_id + '/sheet'
  }

  const handleAnalyze = (bot_id) => {
    window.location.href = '/manage/' + bot_id
  }

  const handleChat = (bot_id) => {
    window.location.href = '/manage/' + bot_id
  }

  return (
    
    <div style={{ padding: 20 }}>
      {/*
      {process.env.REACT_APP_API_URL && process.env.REACT_APP_API_URL != 'https://www.dev-api.wizebot.io/api' && (
        <Helmet><script src="https://chat.botsheets.com/chat.js" data-chatbot-id="652c2070b25c65f014641236" defer></script></Helmet>
      )}
      

      {process.env.REACT_APP_API_URL  && (
        <Helmet><script src="http://localhost:3000/chat-backup.js" data-chatbot-id="6611962ea63fa9a970067c87" defer></script></Helmet>
      )}l
      */}
      
      
      <UpgradeModal text = {"Upgrade your Botsheets plan to create additional chatbots"} user = {user} userPlan = {user.data._doc.plan} isOpen = {isUpgradeModalOpen} onClose = {closeUpgradeModal}/>
      {user && (user.data._doc.plan == "free" || (user.data._doc.plan).includes("ASTier"))
      }
      
   
      <Flex direction={{ base: 'column', md: 'row' }}>
      <VStack spacing={4} flex="1">
      {/* Card for Discovery Chatbot Creation */}
      <Card
          borderWidth="1px"
          borderColor="gray.300"
          borderRadius="md"
          bgColor="white"
          p="3"
          w = "100%"
        >
          <CardBody>
            <Heading size='md' fontWeight = "bold" color = "#38a169">Chatbots</Heading>
            <Text pt = "2" color = "gray.500">Train AI on your business data to answer and ask questions and deliver responses to Google Sheets or external systems.</Text>
            <Button w = "100%" mt = "3" onClick={handleClick} colorScheme="green">Create Chatbot</Button>
            <VideoModalDiscoveryButton />
          </CardBody>
        </Card>

        {/* Card for Data Analysis Chatbot Creation 
        <Card
          borderWidth="1px"
          borderColor="gray.300"
          borderRadius="md"
          bgColor="white"
          p="3"
          w = "100%"
        >
         <CardBody>
        <Heading size='md' fontWeight="bold" color="#38a169">Analysis Bots</Heading>
        <Text pt="2" color="gray.500">AI chatbots that generate data insights with graphs and interactive tables.</Text>
        <CreateAnalysisButton />
        <VideoModalAnalysisButton />
        </CardBody>

        </Card> */}

        <Card
          borderWidth="1px"
          borderColor="gray.300"
          borderRadius="md"
          bgColor="white"
          p="3"
          w = "100%"
        >
          <CardBody>
  <Heading size="md" fontWeight="bold" color="#38a169" display="flex" justifyContent="space-between" alignItems="center">
    Manychat
    <Box 
      bg="#ff6600" 
      color="white" 
      px="2" 
      py="0.5" 
      borderRadius="md" 
      fontSize="sm"
    >
      Add-on
    </Box>
  </Heading>
  <Text pt="2" color="gray.500">
    Manage content for Instagram DM and Facebook Messenger from Google Sheets with Manychat and Botsheets.
  </Text>
  <Button 
    w="100%" 
    mt="3" 
    colorScheme="green" 
    variant="outline" 
    onClick={() => window.open("https://app.botsheets.com", "_blank")}
  >
    Add Manychat Action
  </Button>
</CardBody>

        </Card>

        <Card
          borderWidth="1px"
          borderColor="gray.300"
          borderRadius="md"
          bgColor="white"
          p="3"
          w = "100%"
        >
          <CardBody>
  <Heading size="md" fontWeight="bold" color="#38a169" display="flex" justifyContent="space-between" alignItems="center">
    Report Generator
    <Box 
      bg="#ff6600" 
      color="white" 
      px="2" 
      py="0.5" 
      borderRadius="md" 
      fontSize="sm"
    >
      Add-on
    </Box>
  </Heading>
  <Text pt="2" color="gray.500">
    Instantly turn data in Google Sheets™ into Google Slides and Google Docs with insights and visualizations.
  </Text>
  <Button 
    w="100%" 
    mt="3" 
    colorScheme="green" 
    variant="outline" 
    onClick={() => window.open("https://workspace.google.com/marketplace/app/botsheets_ai_content_generator/401292491579", "_blank")}
  >
    Add Sheets Extension
  </Button>
</CardBody>

        </Card>
        
      </VStack>

      <VStack pl="2" flex="2.5" spacing={4} overflowX="auto">
  {/* Table of existing bots */}
  <TableContainer borderRadius="md" borderColor="gray.300" borderWidth="1px" w="100%">
    <Table bgColor="white" variant="simple">
      <Thead>
        <Tr>
          <Th>My Chatbots</Th>
{/*          <Th>Chatbot Type</Th> */}
          <Th isNumeric>Actions</Th>
        </Tr>
      </Thead>
      <Tbody>
        {bots && bots.map((bot) => (
          <Tr key={bot._id}>
            <Td whiteSpace="normal" wordBreak="break-word">
              <ChakraLink onClick={() => handleViewSheet(bot._id)} color='#1772cc'>
                <Text p="0" size="sm" color="green.500">
                  <strong>{bot.title}</strong>
                </Text>
              </ChakraLink>
            </Td>
{/*            <Td>
              <Text p="0" size="sm" color="gray.500">
                {bot.type === "analysis" ? "Analysis" : "Knowledge"}
              </Text>
            </Td>
 */}            
            <Td isNumeric>
              <ButtonGroup size="sm" spacing={2}>
                <Button variant="outline" colorScheme="green" onClick={() => handleChat(bot._id)}>Chat</Button>
                <Button variant="outline" colorScheme="green" onClick={() => handleViewSheet(bot._id)}>Monitor</Button>
                <Button variant="outline" colorScheme="green" onClick={() => handleManage(bot._id)}>Settings</Button>
              </ButtonGroup>
            </Td>
          </Tr>
        ))}
      </Tbody>
      <Tfoot>
        <Tr>
          {bots.length === 0 && (
            <Td colSpan={3}>
              <Text p="5" color="gray.500" textAlign="center">
                You have not yet created any chatbots. Create one and it will appear here!
              </Text>
            </Td>
          )}
        </Tr>
      </Tfoot>
    </Table>
  </TableContainer>
</VStack>

  </Flex>


      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Additional Google Permission Required</ModalHeader>
          <ModalBody>
          </ModalBody>
          <VStack p = "4" pt = "0" >
            <Text>You forgot to give Botsheets the necessary Google permission to access Google Sheets. You maintain full control over which spreadsheets Botsheets can access and use.</Text>
            <img src="/imgs/permission-error.png" alt = "Google Permissions" width = "100%"/>
            
          </VStack>
          <ModalFooter>
            <Button colorScheme='blue' onClick={logout}>
              Add Permission
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal closeOnOverlayClick={false} isOpen={isStatusModalOpen} onClose={closeStatusModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>AppSumo License Redemption</ModalHeader>
          <ModalBody>
            <p>{message} {status === "success" && 'Plan may take a minute to update.'}</p>      
          </ModalBody>
          <ModalFooter>
            <Button colorScheme='green' onClick={() => window.location.href = 'https://chat.botsheets.com'}>
              View Dashboard
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  )
}

export default Chatbots