import React, { useState } from 'react';
import { Box, CircularProgress,Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Text, FormControl, FormLabel, Input } from '@chakra-ui/react';

const CreatingBotModal = ({ isOpen, onClose, addNewDatapoint }) => {

  return (
    <Modal size="lg" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Creating Your Bot</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box>
          <CircularProgress isIndeterminate color="green.300" />
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default CreatingBotModal;